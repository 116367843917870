$(function() {
    if (exists('.product-container, .product-item')) {
        productComponent();
    }
    if (exists('#catalog-container')) {
	    catalogPage();
    }
    
    loadQtyInputs();
    
    $(".btn-wishlist-to-cart").on('click', function(event) {
		event.preventDefault();
		const product = $(this).parents(".box-wishlist");
		const newModel = (function() {
            if (exists(product.find('select[name=model_id]'))) {
                return product.find("select[name=model_id]")[0].options[
                    product.find("select[name=model_id]")[0].selectedIndex
                ].value;
            }
            else {
                return product.find('[data-model]').data('model');
            }
        })();

        $.ajax({
            type: "POST",
            url: "/restful/shopping/public/checkout/addtocart",
            data: "qty_model_" + newModel + "=1",
            dataType: 'json',
            success: function(data) {
                if (data.success) {
                    showMessageBox("success", msg["MSG_ADDEDTOCART"]);
                }
                else if (data.code == 'MIXED_INTANGIBLE') {
                    showMessageBox('danger', msg['MSG_MIXED_INTANGIBLE']);
                }
                else {
                    showMessageBox('danger', msg['MSG_' + data.code] || msg['MSG_INSUFFICIENT_STOCK']);
                }
            },
            error: function() {
                showMessageBox("danger", msg["MSG_GENERIC_ERROR"]);
            },
        });
	});

	$(".change-model-wishlist").on('change', function () {
		const id = $(this).data("product");
		const val = $(this).val();
        const container = $(this).closest('.box-wishlist');

		container.find(`.product-item__price[data-product=${id}]`).addClass("hide");
		container.find(".product-item__price").addClass('hide').filter(`[data-model=${val}]`).removeClass("hide");
	});

    $('#quantity-contact')
        .on('keydown', function(event) {
            switch (event.key) {
                case "Backspace":
                case "Delete":
                case "Left":
                case "ArrowLeft":
                case "Right":
                case "ArrowRight":
                    break;

                default:
                    if (!event.key.match(/^[0-9]$/)) {
                        event.preventDefault();
                        event.stopImmediatePropagation();
                    }
                    break;
            }
        })
        .on('input focusout', function() {
            const value = parseInt(this.value) || 0;
            this.value = Math.max(1, value).toString();
        });
})

function productComponent() {
	$("#product-slider").slickLightbox({
		itemSelector: ".product-lightgallery",
		src: function(element) {
            return $(element).data('src');
        },
	});

	$("#video-iframe").height($("#product-slider").width());

	$(window).on('resize orientationchange', function () {
		if ($("#video-iframe").length != 0) {
			document.getElementById(
				"vimeo-" + $("#video-iframe").data("video")
			).style.height =
				document.getElementsByClassName("thumb")[0].offsetHeight + "px";
		}
	});

	$(".close-popup").on('click', function () {
		$("#addToCartComplete").foundation("close");
	});

	vimeoLoadingThumb($("#video-iframe").data("video"));
	if ($("#video-iframe").length != 0) {
		document.getElementById(
			"vimeo-" + $("#video-iframe").data("video")
		).style.height =
			document.getElementsByClassName("thumb")[0].offsetHeight + "px";
	}

	$("#open-samples").on("click", function () {
		$(".sample-slick").slick({
			arrows: true,
			infinite: true,
			slidesToShow: 5,
			slidesToScroll: 5,
			responsive: [
				{
					breakpoint: 651,
					settings: {
						slidesToShow: 4,
						slidesToScroll: 4,
						infinite: true,
					},
				},
				{
					breakpoint: 440,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3,
						infinite: true,
					},
				},
				{
					breakpoint: 350,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2,
						infinite: true,
					},
				},
			],
		});

		$(".sample-image")
            .on('mouseover', function () {
                var target = "tags-sample-" + $(this).data("model");
                $("#" + target).toggleClass("hide");
            })
            .on('mouseout', function () {
                var target = "tags-sample-" + $(this).data("model");
                $("#" + target).toggleClass("hide");
            });

		$(".delete-icon").on("click", function () {
			var form = $(this).closest("form");
			$.ajax({
				type: form.attr("method"),
				url: form.attr("action"),
				data: "qty_model_" + $(this).data("model") + "=0",
				dataType: "json",
				success: function (data) {
					if (!data.success) {
						if (data.code == "MIXED_INTANGIBLE") {
							//showMessageBox('danger', msg['MSG_MIXED_INTANGIBLE']);
						} else if (data.code == "MIXED_CART") {
							//showMessageBox('danger', msg['MSG_MIXED_CART']);
						} else if (data.code == "LOGIN_REQUIRED") {
							//location.href = '/' + $('html').attr('lang') + '/login';
						} else {
							showMessageBox("danger", msg["MSG_GENERIC_ERROR"]);
						}
					} else {
						updateCartCount(form);
						//showMessageBox('success', msg['MSG_ADDEDTOCART']);
						setTimeout(function () {
							window.location.reload(true);
						}, 500);
					}
				},
				error: function (xhr, textStatus, errorThrown) {
					showMessageBox("danger", msg["MSG_INSUFFICIENT_STOCK"]);
				},
			});
		});
	});

	$("#close-modal").on("click", function () {
		$("#sample-modal").foundation("close");
	});

	$("#close-modal-not-available").on("click", function () {
		$("#contact-no-quantity").foundation("close");
	});

	$(".qty-max").on("click", function () {
		var $this = $(this);
		var inputName = $this.data("input");
		var input = $('.i-number[name="' + inputName + '"]');
		var inputMax = parseInt(input.data("max"));
		var availability = $("#" + inputName);

		input.val(inputMax);
		availability.text(0);

		$("#add-to-cart").prop("disabled", false);
	});

	$("#qty-max-all").on("click", function () {
		$(".qty-max").each(function () {
			$(this).trigger("click");
		});
	});

	$(".size").on("click", function (event) {
        const container = $(this).closest('.product-container');
		const id = $(this).data("size").toString();
        const wishlistModels = container.find('.wishlist-models').val().split(' ');
        
		$(this)
			.parents(".size-div")
			.siblings(".price-div")
			.find(".price-row, .tax-price-row, .save, .discount, .save-price-row, .price")
			.addClass("hide");
            
		$(".price-" + id).removeClass("hide");
		$("#tax-price-" + id).removeClass("hide");
		$("#discount-" + id).removeClass("hide");
		$("#save-" + id).removeClass("hide");
		$("#save-price-" + id).removeClass("hide");
        container
            .find('.add-to-wishlist, .remove-from-wishlist')
            .find('input')
                .val(id)

        if (wishlistModels.includes(id)) {
            container.find('.div-to-wishlist').addClass('hide');
            container.find('.div-already-wishlist').removeClass('hide');
        }
        else {
            container.find('.div-to-wishlist').removeClass('hide');
            container.find('.div-already-wishlist').addClass('hide');
        }

		setMaxAvailability(this);
	});

	// Automatically select prompt delivery
	$("#product-container .prompt-delivery")
		.first()
		.siblings(".size")
		.trigger("click");

	$(".add-to-wishlist").on("click", function () {
		const model = $(this).children("input");
		addToWishlist(model, 1, msg["MSG_ADDED_TO_WISHLIST"]);
	});
	$(".remove-from-wishlist").on("click", function () {
		const model = $(this).children("input");
		addToWishlist(model, 0, msg["MSG_REMOVED_FROM_WISHLIST"]);
	});

	// Add to cart
	// Add to cart - cart popup-
	$(".addtocart-form",).on("submit", function (event) {
        event.preventDefault();
        const $form = $(this);
        const singleSize = $form.find(".nosize").val();
        const wishlist = $form.attr("wishlist");
        const remove = $form.attr("remove");

        if ($form.data("eu") == "EXTRA-EU") {
            showLargeMessageBox("danger", msg["ERR_EXTRA_EU"]);
            return;
        }

        let selected;
        let target = $form
            .closest(".size-div")
            .find(".size-product input:checked")
            .data("target");
        if (exists(target)) {
            selected = $(target).attr("name");
        } else {
            target = $(".size-product input:checked").data("target");
            selected = $(target).attr("name");
        }

        const noSizeSelected = $form.find(".no-size-selected");
        if (
            exists(noSizeSelected) &&
            noSizeSelected.is(":checked") &&
            not(wishlist) &&
            not(singleSize)
        ) {
            showMessageBox("danger", general_choosesize);
        } else {
            let data;
            let qty_model;

            if (singleSize == "true") {
                qty_model = $form.find(".nosize-input").attr("name");
            } else if (selected) {
                qty_model = selected;
            } else {
                qty_model = $form.find(".firstModel").attr("value");
            }

            if (remove == "true") {
                data = `${qty_model}=0`;
            } else if (wishlist) {
                data = `${qty_model}=1`;
            } else {
                const value = $form.find(".i-number").val();
                data = `${qty_model}=${value}`;
            }

            const quantity = $form.find(".quantity-container").val() >> 0;
            const selectedSizeAvailability = $form
                .find("input[name=size]:checked")
                .data("availability");

            if (quantity > selectedSizeAvailability) {
                if (exists("#contact-no-quantity")) {
                    $("#contact-no-quantity").foundation("open");
                } else {
                    showMessageBox("danger", msg["MSG_INSUFFICIENT_STOCK"]);
                }
            } else {
                $.ajax({
                    type: $form.attr("method"),
                    url: $form.attr("action"),
                    data: data,
                    dataType: "json",
                    success: function (data) {
                        if (data.success) {
                            if (wishlist == "true") {
                                if (remove == "true") {
                                    showMessageBox("success", msg["MSG_REMOVEDTOWISHLIST"]);
                                    $form.find(".div-to-wishlist").removeClass("hide");
                                    $form.find(".div-already-wishlist").addClass("hide");
                                } else {
                                    showMessageBox("success", msg["MSG_ADDEDTOWISHLIST"]);
                                    $form.find(".div-to-wishlist").addClass("hide");
                                    $form.find(".div-already-wishlist").removeClass("hide");
                                }
                            } else {
                                rewixUpdateCart($form);
                                updateCartCount($form);
                                showCartPopup($form);
                            }
                        } else {
                            if (data.code == "MIXED_INTANGIBLE") {
                                showMessageBox("danger", msg["MSG_MIXED_INTANGIBLE"]);
                            } else if (data.code == "MIXED_CART") {
                                showMessageBox("danger", msg["MSG_MIXED_CART"]);
                            } else if (data.code == "RULE_REJECT" && data.rejectCartRuleName) {
                                showMessageBox(
                                    "danger",
                                    (msg["MSG_RULE_REJECT"] || "").replace(
                                        /:rule/g,
                                        data.rejectCartRuleName
                                    )
                                );
                            } else if (data.code == "LOGIN_REQUIRED") {
                                window.location.href = $form.data("nexturl");
                            } else {
                                showMessageBox("danger", msg["MSG_GENERIC_ERROR"]);
                            }
                        }
                    },
                    error: function () {
                        if (exists("#contact-no-quantity")) {
                            $("#contact-no-quantity").foundation("open");
                        } else {
                            showMessageBox("danger", msg["MSG_INSUFFICIENT_STOCK"]);
                        }
                    },
                    complete: function () {
                        if (wishlist == "true") {
                            const [url] = $form.attr("action").split("?");
                            $form.attr("wishlist", "false");
                            $form.attr("action", url);
                        }
                    },
                });
            }
        }
    });

	$(".addtocart-form-sample").on("submit", function (event) {
        event.preventDefault();
        const $form = $(this);

        if ($form.data("eu") == "EXTRA-EU") {
            showLargeMessageBox("danger", msg["ERR_EXTRA_EU"]);
            return;
        }

        if ($("#sample_id_value").attr("name") == "qty_model_") {
            showMessageBox("danger", msg["MSG_SAMPLE_NOT_FOUND"]);
        }
        else {
            $.ajax({
                type: $form.attr("method"),
                url: $form.attr("action"),
                data: $form.serialize(),
                dataType: "json",
                success: function (data) {
                    if (data.success) {
                        rewixUpdateCart($form);
                        updateCartCount($form);
                        showMessageBox("success", msg["MSG_ADDEDTOCART"], null, () => window.location.reload());
                    }
                    else {
                        if (data.code == "MIXED_INTANGIBLE") {
                            showMessageBox("danger", msg["MSG_MIXED_INTANGIBLE"]);
                        }
                        else if (data.code == "MIXED_CART") {
                            showMessageBox("danger", msg["MSG_MIXED_CART"]);
                        }
                        else if (data.code == "RULE_REJECT" && data.rejectCartRuleName) {
                            showMessageBox(
                                "danger",
                                (msg["MSG_RULE_REJECT"] || "").replace(
                                    /:rule/g,
                                    data.rejectCartRuleName
                                )
                            );
                        }
                        else if (data.code == "LOGIN_REQUIRED") {
                            window.location.href = $form.data("nexturl");
                        }
                        else {
                            showMessageBox("danger", msg["MSG_GENERIC_ERROR"]);
                        }
                    }
                },
                error: function () {
                    showMessageBox("danger", msg["MSG_INSUFFICIENT_STOCK"]);
                },
            });
        }
    });

	$("#sample-modal").on("closed.zf.reveal", function () {
		$(".sample-slick").slick("unslick");
	});
}

function showCartPopup(form) {
	const $form = $(form);
	const size = $form.find(".size-product input:checked").data("size-name") || $form.find(".size-div").text();
	const price =
		$form.find(".size-product input:checked").data("price") ||
		$form.find(".nosize").data("price");
	const name = $form.closest(".row").find(".product-item__brand").html();
	const code = $form.closest(".row").find("#codeForReveal").html();
	if (
		$form.closest(".row").find(".product-item__img").find("img")[0] != undefined
	) {
		const imageSrc = $form
			.closest(".row")
			.find(".product-item__img")
			.find("img")[0].src;
		$("#imageReveal").attr("src", imageSrc);
	}
	$("#modelReveal").html(size);
	$("#nameReveal").html(name);
	$("#codeReveal").html(code);
	$("#priceReveal").html(price);
	$.ajax({
		type: "GET",
		beforeSend: function (xhr) {
			xhr.setRequestHeader("Accept", "application/xml");
		},
		url: "/restful/shopping/cart",
		dataType: "xml",
		error: function (xh, txt, err) {
			alert(txt);
		},
		success: function (data) {
			const total = $(data).find("total").text();
			const dispatch = $(data).find("totalDispatchWeight").text();
			if (Math.round(total) - Math.round(dispatch) < 99) {
				$("#currentTotal").html(99 - (Math.round(total) - Math.round(dispatch)));
			}
		},
	});
	$("#addToCartComplete").foundation("open");
}

function updateCartCount(form, callback) {
	$(".header-utilities__wishlist")
		.parent()
		.load(window.location + " .header-utilities__wishlist:lt(1)", function () {
			if (callback) {
				callback();
			}
		});
}

function vimeoLoadingThumb(id) {
	var url = "https://vimeo.com/api/v2/video/" + id + ".json?callback=showThumb";

	var id_img = "#vimeo-" + id;

	var script = document.createElement("script");
	script.src = url;

	$(id_img).before(script);
}

function showThumb(data) {
	var id_img = "#vimeo-" + data[0].id;
	$(id_img).attr("src", data[0].thumbnail_medium);
}

function loadQtyInputs() {
	$(".i-number").each(function () {
		const $this = $(this);

		if ($this.val() > $this.data("max")) {
			$this.css("color", "red");
		}

		if (!$this.data("initialized")) {
            const minus = `
                <a class="i-number-btn i-number-minus" href="#" role="button" tabindex="-1">
                    <img loading="lazy" width=14 height=14 alt="minus" src="/skins/current-skin/images/profile/qty-minus.svg"/>
                </a>
            `;
			$(minus)
				.insertBefore($this)
				.on("click", function (event) {
					event.preventDefault();
					const value = getCurrentValue($this);
					checkValue(value - 1);
				});

            const plus = `
                <a class="i-number-btn i-number-plus" href="#" role="button" tabindex="-1">
                    <img loading="lazy" width=14 height=14 alt="plus" src="/skins/current-skin/images/profile/qty-plus.svg"/>
                </a>
            `;
			$(plus)
				.insertAfter($this)
				.on("click", function (event) {
					event.preventDefault();
					const value = getCurrentValue($this);
					checkValue(value + 1);
				});

			$this.on("keydown input", function (event) {
				const value = getCurrentValue($this);
				const key = (event.key || "").toUpperCase();

				if (key == "ENTER") {
					event.preventDefault();
					$this.closest("form").trigger("submit");
				} else if (key == "ARROWUP") {
					checkValue(value + 1);
				} else if (key == "ARROWDOWN") {
					checkValue(value - 1);
				} else {
					checkValue(value);
				}
			});

			$this.data("initialized", true);

			function getCurrentValue($this) {
				return (
					$this
						.val()
						.toString()
						.replace(/[^0-9]/g, "") >> 0
				);
			}

			function checkValue(value) {
				const min = $this.data("min") >> 0;
				const max = $this.data("max") >> 0;

				if (value < min) {
					$this.val(min);
				} else {
					$this.val(value);
				}
				$this.trigger("change");
			}

			setMaxAvailability($this);
			checkIfOutOfStock($this);
			$this.on("change", () => checkIfOutOfStock($this));
		}
	});
}

function setMaxAvailability(element) {
	const $form = $(element).closest("form");
	const input = $form.find(".i-number");
	const size = $form.find('[name="size"]:checked');
	const nosize = $form.find(".nosize");
	const availability = exists(size)
		? size.data("availability")
		: nosize.data("availability");
	const matomo = exists(size) ? size.data("matomo") : nosize.data("matomo");

	input.data("max", availability).data("matomo", matomo);
	input.trigger("input");
}

function checkIfOutOfStock(element) {
	const $this = $(element);
	const current = $this.val();
	const max =
		$this.data("max") == null ? Number.MAX_SAFE_INTEGER : $this.data("max");

	if (current <= max && max > 0) {
		$this.css("color", "");
		if (exists(".contact-no-quantity")) {
			$(".contact-no-quantity").addClass("hide");
		}
	} else {
		$this.css("color", "red");
		if (exists(".contact-no-quantity")) {
			$(".contact-no-quantity").removeClass("hide");
		}
	}
}
