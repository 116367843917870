$(function () {
	if ($(".g-recaptcha").length > 0) {
		loadCaptcha();
	}

    jqueryDatepicker($);
    const datepicker = $(".datepicker");
	datepicker.datepicker({
        dateFormat: 'yy-mm-dd',
        minDate: datepicker.is('[name=giftcard_date]') ? Date.now() : new Date('1900-01-01'),
        maxDate: datepicker.is('[name=giftcard_date]') ? null : new Date(Date.now() - (18 * 365 + 4) * 24 * 60 * 60 * 1000),
        defaultDate: datepicker.is('[name=giftcard_date]') ? Date.now() : new Date('1970-01-01'),
        changeYear: !datepicker.is('[name=giftcard_date]'),
        yearRange: "c-200:c+200",
    });

	let number = 0;
	$("#sortpicture1").change(function () {
		const file_data = $("#sortpicture1").prop("files")[0];
		const form_data = new FormData();
		form_data.append("file", file_data);
		form_data.append("type", "opi");
		$.ajax({
			url: "https://ftp.dev.zero11.net/storage/opificio/up.php", // point to server-side PHP script
			dataType: "text", // what to expect back from the PHP script, if anything
			cache: false,
			contentType: false,
			processData: false,
			data: form_data,
			type: "post",
			success: function (php_script_response) {
				if (JSON.parse(php_script_response).status == "OK") {
					$("#upload-div-1").addClass("hide");
					$("#image-uploaded-1").removeClass("hide");
					$("#link-image1").val(JSON.parse(php_script_response).link);
					number++;
				}
				else {
					showMessageBox("danger", msg["MSG_GENERIC_ERROR"]);
				}
			},
			error: function () {
				showMessageBox("danger", msg["MSG_GENERIC_ERROR"]);
			},
		});
	});

	$("#to-second-step").on("click", function () {
		to_second_step();
	});

	$("#back-step-1").on("click", function () {
		to_step_1();
	});

	$("#back-step-2").on("click", function () {
		to_step_2();
	});

	$("#back-step-3").on("click", function () {
		to_step_3();
	});

	$("#to-third-step").on("click", function () {
		to_third_step();
	});

	$("#to-four-step").on("click", function () {
		to_four_step();
	});

	$(".private-step1").on("click", function () {
		to_step_1();
	});

	$(".private-step2").on("click", function () {
		to_second_step();
	});

	$(".private-step3").on("click", function () {
		to_third_step();
	});

	$(".private-step4").on("click", function () {
		to_four_step();
	});

	$(".private-send").on("click", function () {
		to_send_private();
	});
});

function findCheckedInput(name) {
	var result = "";
	var x = document.getElementsByName(name);
	var i;
	for (i = 0; i < x.length; i++) {
		if (x[i].checked == true) {
			result = result.concat(x[i].value) + " ";
		}
	}
	return result;
}

function to_step_1() {
	$("#private-contact-step1").removeClass("hide");
	$("#private-contact-step2").addClass("hide");
	$("#private-contact-step3").addClass("hide");
	$("#private-contact-step4").addClass("hide");
}


function to_step_2() {
	$("#private-contact-step1").addClass("hide");
	$("#private-contact-step2").removeClass("hide");
	$("#private-contact-step3").addClass("hide");
	$("#private-contact-step4").addClass("hide");
}


function to_step_3() {
	$("#private-contact-step1").addClass("hide");
	$("#private-contact-step2").addClass("hide");
	$("#private-contact-step3").removeClass("hide");
	$("#private-contact-step4").addClass("hide");
}

function to_second_step() {
	if ($("#private-contact").valid() == true) {
		$("#private-contact-step1").addClass("hide");
		$("#private-contact-step2").removeClass("hide");
		$("#private-contact-step3").addClass("hide");
		$("#private-contact-step4").addClass("hide");
	}
}

function to_send_private() {
}

function to_third_step() {
	if ($("#private-contact").valid() == true) {
		$("#private-contact-step1").addClass("hide");
		$("#private-contact-step2").addClass("hide");
		$("#private-contact-step3").removeClass("hide");
		$("#private-contact-step4").addClass("hide");
	}
}

function to_four_step() {
	if ($("#private-contact").valid() == true) {
		$("#private-contact-step1").addClass("hide");
		$("#private-contact-step2").addClass("hide");
		$("#private-contact-step3").addClass("hide");
		$("#private-contact-step4").removeClass("hide");

		document.getElementById("private-contact-name-lastname").innerHTML =
			$("#private-name").val();
		document.getElementById("private-contact-email").innerHTML =
			$("#private-email").val();
		document.getElementById("private-contact-phone").innerHTML =
			$("#private-phone").val();
		document.getElementById("private-contact-city").innerHTML =
			$("#private-city").val();

		document.getElementById("private-contact-description").innerHTML =
			findCheckedInput("rinnova");

		document.getElementById("private-contact-dimensions").innerHTML = 
			$("#private-space-dimensions").val();
			
		document.getElementById("private-contact-layout-input").innerHTML =
			findCheckedInput("layout");

		if (number != 0) {
			$("#number-count").html($("#number-count").html() + number);
		}
		else {
			$("#private-image-div").addClass("hide");
		}
	}
}

function contactFormRecaptcha() {
	const form = $("#contact-form").get(0);
	if (form.isValid()) {
		form.submit();
	}
}

function contactRecaptchaCallbackPrivate() {
	const form = $("#private-contact").get(0);
	if (form.isValid()) {
		form.submit();
	}
}

function contactPrivateAfterSubmit() {
	$("#private-contact-submit").addClass("private-button-disabled");
}

function contactFormRecaptchaBusiness() {
	const form = $("#contact-form-professionista").get(0);
	if (form.isValid()) {
		form.submit();
	}
}

function contactBusinessAfterSubmit() {
	const $afterSubmit = $('#after-submit');
	if ($afterSubmit.length > 0) {
		$form.addClass('hide');
		$afterSubmit.removeClass('hide');
	}
}